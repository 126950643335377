.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.register-form {
  /* width: 300px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center; */
  width: 50%; /* Adjust the width as needed */
  display: flex; /* Use flexbox to arrange children */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  text-align: center; /* Center text */
}

.register-form h1 {
  margin-left: auto;
  margin-right: auto;
}
  
  .register-form input {
    width: 80%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 0.25rem solid #ccc;
    border-radius: 0.25rem;
  }
  
  .register-form button {
    width: 80%;
    padding: 1rem;
    background-color: #4CAF50;
    color: white;
    border: 0.025rem solid #ccc;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .register-form button:hover {
    background-color: #45a049;
  }
  
  .register-form a {
    color: #FFC30B;
    text-decoration: none;
  }
  
  .register-form a:hover {
    text-decoration: underline;
  }
  
  .back-to-login {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.back-to-login:hover {
  background-color: #eaeaea;
}

  
  .register-image {
    width: 70%; /* Adjust based on your design requirements */
    height: 100vh; /* Make it full height of the viewport */
    overflow: hidden; /* Hide any overflow */
  }
  
  .register-image img {
    width: 100%; /* Allow width to adjust as needed */
    height: 100%; /* Fill the height of the container */
    min-width: 100%; /* Ensure it covers the full width at minimum */
    object-fit: cover; /* Cover the area, cropping as necessary */
    object-position: center; /* Focus on the center of the image */
  }
  
/* 
  .verification-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .verification-title {
    margin-bottom: 20px;
  }
  
  .verification-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .verification-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .verification-button {
    padding: 10px 15px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .verification-success,
  .verification-error {
    color: green;
    margin-top: 15px;
  }
  
  .verification-error {
    color: red;
  }
   */

   /* Apply a flex layout to the body or a main container to center the form */
/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f4f4f4; 
} */

.verification-container {
  width: 100%;
  max-width: 400px; /* Maximum width of the form */
  padding: 20px;
  margin: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.verification-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.verification-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.verification-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.verification-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.verification-button:hover {
  background-color: #0056b3;
}

.verification-message,
.verification-error {
  margin-top: 15px;
}

.verification-error {
  color: #dc3545;
}
