/* forgotpw.css */

/* Main container for the Forgot Password page */
.forgotpw-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full height of the viewport */
  background-color: #f2f2f2; /* Light grey background */
}

/* Style for each input field */
.forgotpw-container input {
  width: 80%; /* Adjust the width as needed */
  margin: 10px auto; /* Centered with margin */
  padding: 10px; /* Padding inside the input fields */
  border-radius: 5px; /* Rounded corners for input fields */
  border: 1px solid #ddd; /* Light grey border for inputs */
}

/* Style for buttons */
.forgotpw-container button {
  width: 80%; /* Match the width of input fields */
  padding: 10px; /* Padding inside the button */
  margin-top: 10px; /* Space above the button */
  background-color: #004a99; /* Button background color */
  color: white; /* White text color */
  border: none; /* No border for the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners for the button */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.forgotpw-container button:hover {
  background-color: #003366; /* Darken the button on hover */
}

.forgotpw-container p1{
  color: #008000; /* Green color */
  margin-top: 10px;
  text-align: center;
}

.forgotpw-container p2 {
  color: #b30000; /* Red color */
  margin-top: 10px;
  text-align: center;
}